export default {
  api: {
    url: 'https://stagapi.farechild.com/api/v1/',
    mode: 'cors',
  },
  headers: {
    Authorization: 'Basic ZmFyZWNoaWxkX3N0YWc6ZmFyZWNoaWxkQHN0YWc=',
    'Content-Type': 'application/json',
  },
};
